import "./ServicesItem.scss"
import {ChevronRight} from "react-bootstrap-icons";
import {FC} from "react";

export const ServicesItem: FC<{ title1: String, title2: String, description: String, imagePath: string, linkPath: string }> = function (props) {

    return (
        <div className="service-item mx-2 my-2">
            <div className="color-square"></div>
            <div className="service-item-bg-img"><img src={props.imagePath} alt=""/></div>
            <div className="p-2">
                <div className="service-item-title">{props.title1}<br/>{props.title2}</div>
            </div>

            <div className="service-item-content">

                <div className="p-2">

                    <div className="service-item-description">
                        {props.description}
                    </div>


                    <div className={"service-view"}><a href={props.linkPath}>View Samples <ChevronRight
                        color={"#D7207E"} size={15}/></a>
                    </div>
                </div>

            </div>

        </div>
    )
}