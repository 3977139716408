import "./SectionTitle.scss"
import {FC} from "react";

export const SectionTitle: FC<{ title: String }> = function (props) {

    return (
        <div className="section-title-head mx-5">
            <div className="section-title-main">
                {props.title}
                <div className="section-title">{props.title}</div>
            </div>
            <div className="section-title-shadow">{props.title}</div>
        </div>
    )
}