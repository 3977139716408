import "./OurServices.scss"
import {SectionTitle} from "../../Components/SectionTitle";
import {ServicesItem} from "../../Components/ServicesItem";
import {Container} from "react-bootstrap";

export function OurServices() {

    return (
        <div id={'services'} className="our-services py-5">

            <div className="pb-5"><SectionTitle title={"Our Services"}/></div>


            <Container className={"mt-5"}>
                <div className="service-items-main">
                    <ServicesItem title1={"Thumbnail"} title2={"Design"}
                                  description={"We provided over 6000+ eye catching extreme quality thumbnails " +
                                      "for websites and social media daily basic and we have capacity of 60 thumbnail " +
                                      "design per day. Best thing about our service is we maintain unique artstyle " +
                                      "for each and every project related to client branding."}
                                  imagePath={'/assets/services/thumbnails.jpg'}
                                  linkPath={"#thumbnails"}/>

                    <ServicesItem title1={""} title2={"Infographic"}
                                  description={"We have provided our infographic service to many projects in the space and our UI " +
                                      "team is available to deliver extra fast work even in daily basis within maximum 3-4 hours. we have " +
                                      "a capacity to do over 15 designs daily and 40 edits daily."}
                                  imagePath={'/assets/services/info.jpg'}
                                  linkPath={"#info"}/>

                    <ServicesItem title1={"UI/UX"} title2={"Design"}
                                  description={"We have highly talented UI /UX designers and developers in our team to cater vast range of " +
                                      "UI design styles and work with latest technologies so you don't have to worry about compatibility. " +
                                      "We provide Mobile apps, websites, game UI designs, and much more."}
                                  imagePath={'/assets/services/uiux.jpg'}
                                  linkPath={""}/>

                    <ServicesItem title1={"Blockchain"} title2={"Development"}
                                  description={"We have highly skilled developer team which capable of taking on any type " +
                                      "of blockchain development works on leading blockchains such as Eth, BSC, Solana, etc"}
                                  imagePath={'/assets/services/blockchain.jpg'}
                                  linkPath={""}/>

                    <ServicesItem title1={"Character"} title2={"Design"}
                                  description={"We have provided over 40+ Character designs through out our course of operation and " +
                                      "they all are 100% unique and custom made for our clients requests."}
                                  imagePath={'/assets/services/character.jpg'}
                                  linkPath={"#character-design"}/>

                    <ServicesItem title1={"NFT"} title2={"Design"}
                                  description={"We have well experienced artists that are worked on 200+ NFT projects and we have " +
                                      "high versatile design styles to make sure that each project is 100% unique in style. We have a " +
                                      "very fast turnaround time for collections."}
                                  imagePath={'/assets/services/nft.jpg'}
                                  linkPath={"#nft"}/>

                    <ServicesItem title1={"Logo"} title2={"Design"}
                                  description={"We provide 100% unique custom LOGO designs with all the brand guidelines for any type of " +
                                      "businesses. also we do Re design works for existing brands to give their deserved look and identity."}
                                  imagePath={'/assets/services/logo.jpg'}
                                  linkPath={""}/>

                    <ServicesItem title1={"Web"} title2={"Development"}
                                  description={"We have an amazing UX designer team and Frontend developers to provide flawless web experience " +
                                      "according to your brand and identity. we always love to use 100% custom unique approach for our designs."}
                                  imagePath={'/assets/services/web.jpg'}
                                  linkPath={""}/>
                </div>
            </Container>

        </div>
    )
}
