import "./PortfolioSection.scss"
import {SectionTitle} from "../../Components/SectionTitle";
import {Container} from "react-bootstrap";
import {FC} from "react";
import {PhotoView} from "react-photo-view";

export const PortfolioSection: FC<{ portfolioTitle: string, portfolioDescription: string, imagePaths?: string[] }> = function (props) {

    return (
        <div className="portfolio-section-one py-5">

            <div className="pb-5"><SectionTitle title={props.portfolioTitle}/></div>

            <Container>
                <div className="my-5 portfolio-description">{props.portfolioDescription}</div>

                <div className="portfolio-section-one-items-main">
                    {props.imagePaths?.map(imagePath => {
                        const fullPath = `/assets/portfolios/original/${imagePath}`;
                        const thumbPath = `/assets/portfolios/thumbs/${imagePath}`;
                        return <div key={imagePath} className="portfolio-item my-4">
                            <PhotoView key={imagePath} src={fullPath}>
                                <img src={thumbPath} alt=""/>
                            </PhotoView>
                        </div>;
                    })}
                </div>
            </Container>
        </div>
    )
}
