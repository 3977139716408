import {NavBar} from "../../Components/NavBar";
import {HeroSection} from "./HeroSection";
import {OurServices} from "./OurServices";
import "./Homepage.scss"
import {Button, Container} from "react-bootstrap";
import {PortfolioSection} from "./PortfolioSection";
import {Testimonials} from "./Testimonials";
import {Partners} from "./Partners";
import {ContactUs} from "./ContactUs";
import {Footer} from "./Footer";

export function Homepage() {
    return (
        <div className="page homepage">
            <NavBar/>
            <HeroSection/>
            <OurServices/>

            <div className="get-in-touch-main py-5">
                <Container>
                    <div className="get-in-touch">
                        <div className={"get-in-touch-text"}>Get in touch with our experts today!</div>
                        <div><Button>Learn More</Button></div>
                    </div>
                </Container>
            </div>

            <div className="thumbnail-designs" id={"thumbnails"}>
                <PortfolioSection portfolioTitle={"Thumbnail Design"}
                                  portfolioDescription={"We provided over 6000+ eye catching extreme quality thumbnails for websites " +
                                      "and social media daily basic and we have capacity of 60 thumbnail design per day. Best thing about " +
                                      "our service is we maintain unique artstyle for each and every project related to client branding."}
                                  imagePaths={[
                                      'thumbnails/1.jpg',
                                      'thumbnails/2.jpg',
                                      'thumbnails/3.jpg',
                                      'thumbnails/4.jpg',
                                      'thumbnails/5.jpg',
                                      'thumbnails/6.jpg',
                                      'thumbnails/7.jpg',
                                      'thumbnails/8.jpg',
                                  ]}/>
            </div>

            <div className="infographic" id={"info"}>
                <PortfolioSection portfolioTitle={"Infographic"}
                                  portfolioDescription={"We have provided our infographic service to many projects in the space " +
                                      "and our UI team is available to deliver extra fast work even in daily basis within maximum 3-4 " +
                                      "hours. we have a capacity to do over 15 designs daily and 40 edits daily."}
                                  imagePaths={[
                                      'infographic/1.png',
                                      'infographic/2.png',
                                      'infographic/3.png',
                                      'infographic/4.png',
                                      'infographic/5.png',
                                      'infographic/6.png',
                                      'infographic/7.png',
                                      'infographic/8.png',
                                  ]}
                />
            </div>

            {/*<div className="ui-ux-design">*/}
            {/*    <PortfolioSection portfolioTitle={"UI/UX Design"}*/}
            {/*                      portfolioDescription={"We provide eye catching extreme" +*/}
            {/*                          " quality ui/ux designs for websites with extreme fast turn around time. Best thing about our " +*/}
            {/*                          "service is we maintain unique artstyle for each and every project related to client branding."}/>*/}
            {/*</div>*/}

            {/*<div className="blockchain-development">*/}
            {/*    <PortfolioSection portfolioTitle={"Blockchain Development"}*/}
            {/*                      portfolioDescription={"We provide blockchain " +*/}
            {/*                          "development for websites with extreme fast turn around time. Best thing about our service is we " +*/}
            {/*                          "maintain unique artstyle for each and every project related to client branding."}/>*/}
            {/*</div>*/}

            <div className="character-design" id={"character-design"}>
                <PortfolioSection portfolioTitle={"Character Design"}
                                  portfolioDescription={"We have provided over 40+ Character designs through out our course of " +
                                      "operation and they all are 100% unique and custom made for our clients requests."}
                                  imagePaths={[
                                      'characters/1.jpg',
                                      'characters/2.jpg',
                                      'characters/3.jpg',
                                      'characters/4.jpg',
                                      'characters/5.jpg',
                                      'characters/6.jpg',
                                      'characters/7.jpg',
                                      'characters/8.jpg',
                                  ]}
                />
            </div>

            <div className="NFT-design" id={"nft"}>
                <PortfolioSection portfolioTitle={"NFT Design"}
                                  portfolioDescription={"We have well experienced artists that are worked on 200+ NFT projects and " +
                                      "we have high versatile design styles to make sure that each project is 100% unique in style. " +
                                      "We have a very fast turnaround time for collections."}
                                  imagePaths={[
                                      'nft/1.png',
                                      'nft/2.png',
                                      'nft/3.png',
                                      'nft/5.png',
                                      'nft/6.png',
                                      'nft/7.png',
                                      'nft/8.png',
                                      'nft/9.png',
                                  ]}
                />
            </div>

            <Testimonials/>
            <Partners/>
            <ContactUs/>
            <Footer/>

        </div>
    )
}
