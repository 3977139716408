import {Container, Nav, Navbar} from "react-bootstrap";
import "./NavBar.scss"
import {HashLink} from "react-router-hash-link";

export function NavBar() {
    return (
        <div className="NavBar">
            <div className="navbar-desktop-main">
                <div className="navbar-desktop py-4">
                    <div className="navbar-desktop-item"><HashLink to={"#home"}>Home</HashLink></div>
                    <div className="navbar-desktop-item"><HashLink to={'#services'}>Our Services</HashLink></div>
                    <div className="navbar-desktop-item"><HashLink to={'#testimonials'}>Testimonials</HashLink></div>
                    <div className="navbar-desktop-item"><HashLink to={'#clients'}>Clients</HashLink></div>
                    <div className="navbar-desktop-item"><HashLink to={'#contact'}>Contact Us</HashLink></div>
                </div>
            </div>

            <div className="navbar-mobile-main">
                <div className="navbar-mobile">
                    <Navbar expand="xxl">
                        <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link href="#home">Home</Nav.Link>
                                    <Nav.Link href="#home">Our Services</Nav.Link>
                                    <Nav.Link href="#home">Testimonials</Nav.Link>
                                    <Nav.Link href="#home">Clients</Nav.Link>
                                    <Nav.Link href="#link">Contact Us</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>

        </div>
    )
}
