import "./TestimonialItem.scss"
import {Twitter} from "react-bootstrap-icons";
import {FC} from "react";

export const TestimonialItem: FC<{ testimonialName: string, testimonialProfileTag: string, testimonial: string }> = function (props) {

    return (
        <div className="testimonial-item">
            <div className="p-4">
                <div className="profile-row">
                    <div className="profile-info">
                        <div className="profile-pic"></div>
                        <div className="profile-name-main mx-3 mt-4">
                            <div className="profile-name">{props.testimonialName}</div>
                            <div className="profile-tag">{props.testimonialProfileTag}</div>
                        </div>
                    </div>

                    <div className={"mt-4"}><Twitter color={"#D7207E"} size={30}/></div>
                </div>

                <div className="feedback mt-4">
                    {props.testimonial}
                </div>

                <div className="time mt-2">10.20 AM - 21 / 11 / 2022 - Twitter for iPhone</div>
            </div>
        </div>
    )
}