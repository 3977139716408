import "./ContactUs.scss"
import {SectionTitle} from "../../Components/SectionTitle";
import {Button, Col, Container, Row} from "react-bootstrap";
import {EnvelopeFill, GeoAltFill, TelephoneFill} from "react-bootstrap-icons";

export function ContactUs() {

    return (
        <div id={'contact'} className="contact-us py-5">
            <div className="contact-us-bg"></div>
            <div className="pb-5"><SectionTitle title={"Contact Us"}/></div>
            <Container className="contact-us-head mt-5">
                <Row>
                    <Col xl={6}>
                        <div className={"contact-us-img"}>
                            <img src="/assets/contact/contact.png" alt=""/>
                        </div>
                    </Col>

                    <Col xl={6}>
                        <div className="contact-details-section">
                            <div className={"contact-details-section-main"}>
                                <div className="contact-description">
                                    Whether you’re curious about anything <br/>
                                    we’re ready to answer any and all questions.
                                </div>

                                <div className="contact-details-main">
                                    <div className="contact-detail-row mt-4">
                                        <div className="contact-icon"><TelephoneFill color={"#D7207E"}/></div>
                                        <div className={"contact-detail mx-4"}>(940) 236-3745</div>
                                    </div>
                                    <div className="contact-detail-row mt-4">
                                        <div className="contact-icon"><EnvelopeFill color={"#D7207E"}/></div>
                                        <div className={"contact-detail mx-4"}>contact@plebs.studio</div>
                                    </div>
                                    <div className="contact-detail-row mt-4">
                                        <div className="contact-icon"><GeoAltFill color={"#D7207E"}/></div>
                                        <div className={"contact-detail mx-4"}>
                                            Plebstudios LLC,<br/>
                                            30 N Gould St Ste R,<br/>
                                            Sheridan,<br/>
                                            WY 82801, United States.<br/>
                                        </div>
                                    </div>
                                </div>

                                <div className={"mt-5"}>
                                    <Button className={"contact-us-btn"}>Contact Us</Button>
                                </div>
                            </div>

                        </div>


                    </Col>
                </Row>
            </Container>
        </div>
    )
}
