import "./Testimonials.scss"
import {SectionTitle} from "../../Components/SectionTitle";
import {Button, Col, Container, Row} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import {TestimonialItem} from "../../Components/TestimonialItem";
import "react-multi-carousel/lib/styles.css";
import {Discord, Twitter} from "react-bootstrap-icons";

export function Testimonials() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 1
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <div id={'testimonials'} className="testimonials py-5">

            <div className="pb-5"><SectionTitle title={"Testimonials"}/></div>

            <div className="testimonial-bg"></div>

            <Container className={"mt-5"}>

                <Row className={"testimonial-row py-5"}>

                    <Col xl={7} className={"carousel-main pb-5 pb-xl-0"}>
                        <Carousel responsive={responsive}>
                            <div>
                                <TestimonialItem
                                    testimonialName={"CEO"}
                                    testimonialProfileTag={"Mhventures"}
                                    testimonial={"Being able to turn round graphics quick is something we as a venture\n" +
                                        "                                    capitalist need in the crypto space and plebstudio has the talent plus the speed!\n" +
                                        "                                    Recommended!"}/>
                            </div>
                            <div>
                                <TestimonialItem
                                    testimonialName={"Greg Gotsis"}
                                    testimonialProfileTag={"Mhventures"}
                                    testimonial={"Pleb's Studio merges quality and speed. The artwork and detail is unbelievable alongside the willingness to " +
                                        "work with clients. The team has provided nearly 10,000 graphics for our media network between Web3 Wire, " +
                                        "Solana News, BSCNews and Cronos News. The design team thinks out of the box and with the right" +
                                        "direction can hone in on the style and message you are trying to convey. On top of" +
                                        "my testament, projects we work with echo this. Plebs Studio has brought life to our media network and makes " +
                                        "up the backbone of image."}/>
                            </div>
                            <div>
                                <TestimonialItem
                                    testimonialName={"Hash"}
                                    testimonialProfileTag={"Co-Founder of DX Sale"}
                                    testimonial={"Without pleb, we wouldn't have been able to get the graphics we needed on time " +
                                        "and at such a high quality. I've never worked with a contractor who" +
                                        "delivered with such result. I look forward to working with his team again"
                                    }/>
                            </div>
                            <div>
                                <TestimonialItem
                                    testimonialName={"Kamran"}
                                    testimonialProfileTag={"COO & CO - Founder of Bsc News"}
                                    testimonial={"Plebstudio has been a huge asset to us at BSCNews and assisted with use" +
                                        "stablishing branding! The efficiency of their whole team is amazing! I cherish the" +
                                        "long term relationship."}/>
                            </div>
                        </Carousel>
                    </Col>

                    <Col xl={5}>
                        <div className="testimonial-col">
                            <div className="testimonial-sub-title mb-4">Join The Community</div>
                            <div className={"testimonial-description"}>Meet the community, artists and collectors for
                                platform updates, announcement and
                                more...
                            </div>
                            <div className="buttons-row mt-4">
                                <Button><Twitter color={"#D7207E"} size={25}/> Twitter</Button>
                                <Button className={"mx-4"}><Discord color={"#D7207E"} size={25}/> Discord</Button>
                            </div>
                        </div>

                    </Col>
                </Row>


            </Container>

        </div>
    )
}
