import "./Footer.scss"
import {Container} from "react-bootstrap";
import {Instagram, Linkedin, Twitter} from "react-bootstrap-icons";

export function Footer() {

    return (
        <div className="footer py-5">
            <Container className={"py-5"}>
                <img src="/assets/logo/logo-white.png" className={"footer-logo mt-5"} alt=""/>
                <div className="footer-description mt-4">We have provided our expertise for many projects including some
                    of famous projects in the market as well.
                </div>

                <div className="social-icons mt-5">
                    <a href={'https://twitter.com/PlebsStudio'} target={'_blank'}>
                        <div><Twitter color={"#D7207E"} size={25}/></div>
                    </a>
                    <a href={'https://www.linkedin.com/company/pleb-studios-llc/'} target={'_blank'}>
                        <div><Linkedin color={"#D7207E"} size={25}/></div>
                    </a>
                    <a href={'https://www.instagram.com/bscpleb_nft/'} target={'_blank'}>
                        <div><Instagram color={"#D7207E"} size={25}/></div>
                    </a>
                </div>


            </Container>

            <a href={"https://g2.getterms.io/g/saC1V"} target={'_blank'}
               style={{textDecoration: 'none', color: 'white'}}>
                <p className="foot-note text-center mt-5">Privacy Policy</p></a>
            <div className="foot-note text-center">© 2022 Plebtudio LLC. All Rights Reserved.</div>
        </div>
    )
}
