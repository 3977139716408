import "./Partners.scss"
import {SectionTitle} from "../../Components/SectionTitle";
import {PartnerItem} from "../../Components/PartnerItem";
import {Container} from "react-bootstrap";
import Carousel from "react-multi-carousel";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 4
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 4
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 4
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 2
    }
};

export function Partners() {

    return (
        <div id={'clients'} className="partners py-5">

            <div className="pb-5"><SectionTitle title={"Partners"}/></div>

            <div style={{backgroundColor: "white"}}>
                <Container className={"mt-5 py-5"}>
                    <Carousel showDots={true} infinite={true} responsive={responsive} className={'pb-5'}>
                        <PartnerItem partnerName={"NZXT"} logo={'/assets/partners/client-logo-bscnews.png'}
                                     url={'https://www.bsc.news/'}/>
                        <PartnerItem partnerName={"DigiGraph"} logo={'/assets/partners/client-logo-openocean.png'}
                                     url={'https://openocean.finance/'}/>
                        <PartnerItem partnerName={"Triangle.C"} logo={'/assets/partners/client-logo-dx.png'}
                                     url={'https://dxsale.app/'}/>
                        <PartnerItem partnerName={"D-TREx"} logo={'/assets/partners/client-logo-safelaunch.png'}
                                     url={'https://safelaunch.io/'}/>
                        <PartnerItem partnerName={"IndieAT"} logo={'/assets/partners/client-logo-mh.png'}
                                     url={'https://www.mhventures.io/'}/>
                        <PartnerItem partnerName={"Camel.io"} logo={'/assets/partners/client-logo-gat.png'}
                                     url={'https://gat.network/'}/>
                        <PartnerItem partnerName={"Lovely Inu"} logo={'/assets/partners/client-logo-lovely.png'}
                                     url={'https://lovely.finance/'}/>
                    </Carousel>
                </Container>
            </div>

        </div>
    )
}
