import "./HeroSection.scss"
import {Button, Container} from "react-bootstrap";

export function HeroSection() {

    return (
        <div className="hero-section" id={'home'}>
            <Container>
                <div className="hero-section-content">
                    <img src="/assets/hero-section/hero-bg-images.png" className={"hero-section-bg-img"} alt=""/>

                    <div className="pleb-logo">
                        <img src="/assets/logo/logo-black.png" alt=""/>
                    </div>

                    <div className="contact-us-section">
                        <div className={"contact-us-section-description"}>Creativity with the power to transform. True
                            excellence is a product of synergy.
                        </div>

                        <Button className={"pink mt-4"}>Contact Us</Button>
                    </div>
                </div>
            </Container>

            <div className="pleb-studio-advantages">
                <Container className={"py-5"}>
                    <div className="advantages-item-main">
                        <div className="advantage-item my-4">
                            <div className="pink-square"></div>
                            <div className="advantage-item-title">Pleb Team</div>
                            <div className="advantage-item-content">
                                <img src="/assets/hero-section/advantages/advantages-1.png" width={40} alt=""/>

                                <div className="advantage-item-description mt-3">Our well experienced, Industry expert
                                    Design
                                    team and Development team are always upto date with latest technologies of the
                                    industry
                                    and Web3 products.
                                </div>
                            </div>
                        </div>
                        <div className="advantage-item my-4">
                            <div className="pink-square"></div>
                            <div className="advantage-item-title">Top Quality Service</div>
                            <div className="advantage-item-content">
                                <img src="/assets/hero-section/advantages/advantages-2.png" width={40} alt=""/>

                                <div className="advantage-item-description mt-3">We always try to provide 100% Unique
                                    products
                                    and we maintain high standards when it comes to originality of the product.
                                </div>
                            </div>
                        </div>
                        <div className="advantage-item my-4">
                            <div className="pink-square"></div>
                            <div className="advantage-item-title">Long-Term Business relationship</div>
                            <div className="advantage-item-content">
                                <img src="/assets/hero-section/advantages/advantages-3.png" width={40} alt=""/>

                                <div className="advantage-item-description mt-3">
                                    Our service is always open to invest in Business relationship if our service needed
                                    regularly, we have special packages available for our clients as well.
                                </div>
                            </div>
                        </div>
                        <div className="advantage-item my-4">
                            <div className="pink-square"></div>
                            <div className="advantage-item-title">Fast Turnaround Time</div>
                            <div className="advantage-item-content">
                                <img src="/assets/hero-section/advantages/advantages-4.png" width={40} alt=""/>

                                <div className="advantage-item-description mt-3">We provide flawless deliveries within
                                    very short
                                    amount of time. we are also famous within our clients for our fast and quality
                                    service.
                                </div>
                            </div>
                        </div>
                        <div className="advantage-item my-4">
                            <div className="pink-square"></div>
                            <div className="advantage-item-title">On-Time Delivery</div>
                            <div className="advantage-item-content">
                                <img src="/assets/hero-section/advantages/advantages-5.png" width={40} alt=""/>

                                <div className="advantage-item-description mt-3">We know how time sensitive your
                                    projects are.
                                    Our team is dedicated to meet your deadlines and exceed the quality you expect.
                                </div>
                            </div>
                        </div>
                    </div>

                </Container>
            </div>

        </div>
    )
}
