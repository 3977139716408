import "./PartnerItem.scss"
import {FC} from "react";

export const PartnerItem: FC<{ partnerName: string, logo: string, url: string }> = function (props) {
    return (
        <div className="partner-item m-4">
            <a href={props.url} target={'_blank'}>
                <img src={props.logo} alt={props.partnerName}/>
            </a>
        </div>
    )
}
